@font-face {
    font-family: 'Prompt-Regular';
    src: url('assets/fonts/Prompt-Regular.ttf');
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: "Prompt-Regular", sans-serif;
}

.box {
    position: relative;
    text-align: center;
    margin-bottom: 10px;
}

.content {
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    
}

.content-inside {
    margin-top: 50px;
}

.centered-desktop {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.centered-mobile {
    position: absolute;
    top: 18%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.date-content {
    color: #000;
    background-color: #eae6dc;
    border: 5px solid #ffc455;
    border-radius: 30px;
    padding: 10px;
    width: fit-content;
    margin: 70px auto 20px auto;

    -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    -webkit-transition: .4s;
    transition: .4s;
}

.ones-prize {
    border: 5px solid #ffc455;
    background-color: #fff;
    border-radius: 30px;
    padding: 5px 20px;
    width: fit-content;
    margin: 5px auto 20px auto;
    display: flex;

    -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    -webkit-transition: .4s;
    transition: .4s;
}

.two-prize {
    border: 5px solid #ffc455;
    background-color: #fff;
    border-radius: 30px;
    padding: 5px 20px;
    width: fit-content;
    margin: 5px auto 50px auto;
    display: flex;

    -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    -webkit-transition: .4s;
    transition: .4s;
}

@media only screen and (max-width: 600px) {
    .date-content {
        margin: 5px auto 5px auto;
        padding: 4px;
    }

    .ones-prize {
        margin: 5px auto 5px auto;
        padding: 5px;
    }

    .two-prize {
        margin: 5px auto 5px auto;
        padding: 5px;
    }

    .content-inside {
        margin-top: 0;
    }

    .container .box > img {
        width: 100% !important;
        height: 320px;
    }

    .container .box .content > img {
        width: 160px !important;
        height: 110px !important;
    }
}


.statistic-content {
    background-color: #fff;
    width: fit-content;
    /* border: 2px solid #de0d9d; */
    margin: 5px auto 5px auto;
    padding: 5px 10px 5px 10px;

    -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    -webkit-transition: .4s;
    transition: .4s;
}

.balanced {
    text-align: justify;
}

.balanced:after {
    content: "";
    display: inline;
    width: 100%;
}